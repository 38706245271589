<template>
  <div>
    <b-modal
      v-model="isModalShow"
      hide-footer
      title="อัพโหลดไฟล์เลขพัสดุ"
      @hide="setShowModalImport(false)"
    >
      <div>
        <label>ประเภทการจัดส่ง</label>
        <small class="text-danger" v-if="isValidate && typeSelected === null">
          กรุณาเลือกประเภทการจัดส่ง</small
        >
        <div>
          <b-form-select
            v-model="typeSelected"
            :options="shippingLists"
            class="mb-3"
            value-field="id"
            text-field="name"
            disabled-field="notEnabled"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled
                >กรุณาเลือกประเภทการจัดส่ง</b-form-select-option
              >
            </template>
          </b-form-select>
        </div>
      </div>
      <div>
        <label>เลือกไฟล์ที่ต้องการอัพโหลด</label>
        <small class="text-danger" v-if="isValidate && !file">
          กรุณาเลือกไฟล์</small
        >
        <b-form-file
          v-model="file"
          :state="Boolean(fileCheck)"
          placeholder="เลือกไฟล์ที่ต้องการ"
          browse-text="เลือกไฟล์"
          accept=".xlsx, .xls, .csv"
        ></b-form-file>
      </div>

      <div class="d-flex justify-content-between mt-3">
        <div class="d-flex mt-1">
          <b-form-checkbox
            v-model="selectedUpdateCustomer"
            name="selectedUpdateCustomer"
            id="selectedUpdateCustomer"
          >
          </b-form-checkbox>
          <label class="form-check-label" for="selectedUpdateCustomer"
            >อัพเดทลูกค้าทันที</label
          >
        </div>
        <div>
          <button
            class="btn btn-secondary mr-1"
            @click="setShowModalImport(false)"
          >
            ยกเลิก
          </button>
          <button
            class="btn text-white bg-color-primary"
            id="importButton"
            @click="uploadItem()"
          >
            อัพโหลด
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ImportTracking",
  props: {
    isShow: {
      required: false,
      type: Boolean
    },
    shippingLists: Array,
    uploadTracking: Function
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.themeStyle.backgroundColor = this.mainColor;
      this.themeStyle.borderColor = this.mainColor;
    });
  },
  computed: {
    isModalShow: {
      get: function() {
        return this.isShow;
      },
      set: function() {}
    },
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      brandLists: "getBrandLists",
      mainColor: "getBrandColor"
    })
  },
  data() {
    return {
      file: null,
      fileCheck: true,
      selectedShippingMethod: null,
      typeSelected: null,
      isValidate: false,
      uploadResult: false,
      themeStyle: {
        backgroundColor: "",
        borderColor: "",
        color: "white"
      },
      selectedUpdateCustomer: false
      // shippingLists: [
      //   { id: null, name: "เลือกประเภทการจัดส่ง", notEnabled: true },
      //   { id: 1, name: "Alpha" },
      //   { id: 2, name: "Kerry" }
      // ]
    };
  },
  methods: {
    validate() {
      if (this.typeSelected === null) return false;
      if (!this.file) return false;

      return true;
    },
    setShowModalImport(value) {
      this.$emit("setShowModalImport", value);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async uploadItem() {
      this.isValidate = true;
      if (this.validate()) {
        this.uploadTracking(
          this.typeSelected,
          await this.toBase64(this.file),
          this.selectedUpdateCustomer
        );
        this.isValidate = false;
      }
    }
  }
};
</script>

<style scoped></style>
