<template>
  <b-modal
    v-model="isModalShow"
    title="Brand สินค้า"
    scrollable
    hide-footer
    @hide="setShowModalBrand(false)"
  >
    <div>
      <b-row>
        <b-col>
          <b-form-group
            id="input-group-1"
            label="เลือก Brand"
            label-for="input-1"
          >
            <b-form-select
              v-model="brandSelected"
              :options="brandLists"
              class=""
              value-field="id"
              text-field="name"
              disabled-field="notEnabled"
              @change="onBrandSelected(brandSelected)"
            >
              <template v-slot:first>
                <b-form-select-option value="null" disabled
                  >-- Please select an option --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="item-end">
        <b-col>
          <button
            @click="setShowModalBrand(false)"
            class="btn btn-secondary mr-1"
          >
            ยกเลิก
          </button>
          <b-button
            class="btn btn-info bg-color-black"
            id="btnModalOk"
            @click="onSubmitedBrand"
            >ตกลง</b-button
          >
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
// import TextInput from "../components/TextInput";
import { mapGetters } from "vuex";
export default {
  name: "SelectBrand",
  components: {
    // TextInput
  },
  props: {
    buyerInfo: Object,
    isShow: {
      required: false,
      type: Boolean
    },
    onBrandChange: Function
  },
  data() {
    return {
      filter: {
        page: 1,
        search: "",
        take: 20
      },
      themeStyle: {
        backgroundColor: "",
        borderColor: "",
        color: "white"
      },
      brandSelected: this.$route.query.brandID,
      address: null,
      isValidate: false,
      isSamePayment: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.themeStyle.backgroundColor = this.mainColor;
      this.themeStyle.borderColor = this.mainColor;
    });
  },
  computed: {
    isModalShow: {
      get: function() {
        return this.isShow;
      },
      set: function() {}
    },
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      brandLists: "getBrandLists",
      mainColor: "getBrandColor"
    })
  },
  methods: {
    setShowModalBrand(value, brandSelected) {
      if (brandSelected) {
        this.$emit("setShowModalBrand", value, this.brandSelected);
      } else {
        this.$emit("setShowModalBrand", value);
      }
    },
    onSubmitedBrand() {
      this.setShowModalBrand(false, this.brandSelected);
    },
    onBrandSelected(value) {
      this.brandSelected = value;
      //this.onBrandChange(value);
    }
  }
};
</script>

<style lang="scss" scoped></style>
